import {
  HEART_BEAT_FAILED,
  HEART_BEAT_REQUESTED,
  HEART_BEAT_RETRIEVED
} from './types';
import AuthRestClient from '../../http/client/Authentication';

// `STATE_REGISTERING`
// `STATE_WELCOME`
// `STATE_APP`
// `STATE_MAINTENANCE`
export const setAppState = state => dispatch => {
  dispatch({ type: state });
};

export const heartbeat = () => {
  const requested = () => ({ type: HEART_BEAT_REQUESTED });
  const successfull = response => ({
    type: HEART_BEAT_RETRIEVED,
    heartbeat: response.data
  });
  const errored = error => ({ type: HEART_BEAT_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return AuthRestClient.heartbeat()
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};
