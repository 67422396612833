import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Moment from 'react-moment';

import RTVRating from './RTVRating';
import RTVAvatar from './RTVAvatar';

const styles = theme => ({
  root: {
    flex: 1,
    padding: theme.spacing(1),
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
    '& > *': {
      width: '100%'
    }
  },
  authorSection: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  review: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
    fontStyle: 'italic'
  },
  answer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: 0,
    fontStyle: 'italic'
  },
  answerText: {
    textAlign: 'right'
  },
  date: {
    color: theme.palette.text.secondary,
    textAlign: 'center'
  }
});

const RTVReview = withStyles(styles)(({ classes, review }) => (
  <div className={classes.root}>
    <div className={classes.authorSection}>
      <RTVAvatar
        style={{ marginRight: '8px' }}
        src={
          review.author.media
            ? review.author.media.default_small ||
              review.author.media.user_small
            : null
        }
      />
      <Typography style={{ fontWeight: 600 }}>
        {review.author.username}
      </Typography>
    </div>
    <div className={classes.review}>
      <Typography className={classes.reviewText}>
        {review.censored_review
          ? '**La calificación fue moderada por no cumplir con las políticas del sitio.**'
          : `"${review.review}"`}
      </Typography>
    </div>
    {review.answer !== undefined && review.answer !== null && (
      <div className={classes.answer}>
        <Typography className={classes.answerText}>
          {review.censored_answer
            ? '**La respuesta fue moderada por no cumplir con las políticas del sitio.**'
            : `"${review.answer}"`}
        </Typography>
      </div>
    )}
    <RTVRating
      style={{ justifyContent: 'center' }}
      name="review-rating"
      value={review.stars}
      readOnly
    />
    <Moment
      className={classes.date}
      date={review.datetime}
      format="ll"
      locale="es"
    />
  </div>
));

export default RTVReview;
