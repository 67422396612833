import {
  FINANCIAL_OPTIONS_REQUESTED,
  FINANCIAL_OPTIONS_RETRIEVED,
  FINANCIAL_OPTIONS_FAILED,
  USER_CARDS_REQUESTED,
  USER_CARDS_RETRIEVED,
  USER_CARDS_FAILED,
  CREATE_CARD_REQUESTED,
  CREATE_CARD_SUCCESSFULL,
  CREATE_CARD_ERRORED,
  DELETE_CARD_REQUESTED,
  DELETE_CARD_SUCCESSFULL,
  DELETE_CARD_ERRORED,
  ORDER_RECEIPT_UPLOAD_REQUESTED,
  ORDER_RECEIPT_UPLOAD_SUCCESSFULL,
  ORDER_RECEIPT_UPLOAD_ERRORED,
  FINANCIAL_RECORDS_REQUESTED,
  FINANCIAL_RECORDS_RETRIEVED,
  FINANCIAL_RECORDS_FAILED,
  FINANCIAL_TOTALS_REQUESTED,
  FINANCIAL_TOTALS_RETRIEVED,
  FINANCIAL_TOTALS_FAILED,
  CREATE_WITHDRAWAL_REQUESTED,
  CREATE_WITHDRAWAL_SUCCESSFULL,
  CREATE_WITHDRAWAL_ERRORED,
  LOGOUT_REQUESTED,
  BANKS_REQUESTED,
  BANKS_SUCCESSFULL,
  BANKS_ERRORED
} from '../actions/types';

const defaultState = {
  error: null,
  financialOptions: [],
  order: null,
  totals: [],
  records: [],
  status: null,
  cards: [],
  banks: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGOUT_REQUESTED: {
      return defaultState;
    }
    case FINANCIAL_OPTIONS_REQUESTED: {
      return {
        ...state,
        status: FINANCIAL_OPTIONS_REQUESTED
      };
    }
    case FINANCIAL_OPTIONS_RETRIEVED: {
      return {
        ...state,
        status: FINANCIAL_OPTIONS_RETRIEVED,
        financialOptions: action.financialOptions
      };
    }
    case FINANCIAL_OPTIONS_FAILED: {
      return {
        ...state,
        status: FINANCIAL_OPTIONS_FAILED,
        error: action.error
      };
    }
    case USER_CARDS_REQUESTED: {
      return {
        ...state,
        status: USER_CARDS_REQUESTED
      };
    }
    case USER_CARDS_RETRIEVED: {
      return {
        ...state,
        status: USER_CARDS_RETRIEVED,
        cards: action.cards
      };
    }
    case USER_CARDS_FAILED: {
      return {
        ...state,
        status: USER_CARDS_FAILED,
        error: action.error
      };
    }
    case CREATE_CARD_REQUESTED: {
      return {
        ...state,
        status: CREATE_CARD_REQUESTED
      };
    }
    case CREATE_CARD_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_CARD_SUCCESSFULL,
        cards: action.cards
      };
    }
    case CREATE_CARD_ERRORED: {
      return {
        ...state,
        status: CREATE_CARD_ERRORED,
        error: action.error
      };
    }
    case DELETE_CARD_REQUESTED: {
      return {
        ...state,
        status: DELETE_CARD_REQUESTED
      };
    }
    case DELETE_CARD_SUCCESSFULL: {
      return {
        ...state,
        status: DELETE_CARD_SUCCESSFULL,
        cards: action.cards
      };
    }
    case DELETE_CARD_ERRORED: {
      return {
        ...state,
        status: DELETE_CARD_ERRORED,
        errror: action.error
      };
    }
    case ORDER_RECEIPT_UPLOAD_REQUESTED: {
      return {
        ...state,
        status: ORDER_RECEIPT_UPLOAD_REQUESTED,
        order: action.order
      };
    }
    case ORDER_RECEIPT_UPLOAD_SUCCESSFULL: {
      return {
        ...state,
        status: ORDER_RECEIPT_UPLOAD_SUCCESSFULL,
        order: action.order
      };
    }
    case ORDER_RECEIPT_UPLOAD_ERRORED: {
      return {
        ...state,
        status: ORDER_RECEIPT_UPLOAD_ERRORED,
        error: action.error,
        order: action.order
      };
    }
    case FINANCIAL_RECORDS_REQUESTED: {
      return {
        ...state,
        status: FINANCIAL_RECORDS_REQUESTED
      };
    }
    case FINANCIAL_RECORDS_RETRIEVED: {
      return {
        ...state,
        status: FINANCIAL_RECORDS_RETRIEVED,
        records: action.records
      };
    }
    case FINANCIAL_RECORDS_FAILED: {
      return {
        ...state,
        status: FINANCIAL_RECORDS_FAILED,
        error: action.error
      };
    }
    case FINANCIAL_TOTALS_REQUESTED: {
      return {
        ...state,
        status: FINANCIAL_TOTALS_REQUESTED
      };
    }
    case FINANCIAL_TOTALS_RETRIEVED: {
      return {
        ...state,
        status: FINANCIAL_TOTALS_RETRIEVED,
        totals: action.totals
      };
    }
    case FINANCIAL_TOTALS_FAILED: {
      return {
        ...state,
        status: FINANCIAL_TOTALS_FAILED,
        error: action.error
      };
    }
    case CREATE_WITHDRAWAL_REQUESTED: {
      return {
        ...state,
        status: CREATE_WITHDRAWAL_REQUESTED
      };
    }
    case CREATE_WITHDRAWAL_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_WITHDRAWAL_SUCCESSFULL
      };
    }
    case CREATE_WITHDRAWAL_ERRORED: {
      return {
        ...state,
        status: CREATE_WITHDRAWAL_ERRORED
      };
    }
    case BANKS_REQUESTED: {
      return {
        ...state,
        status: BANKS_REQUESTED
      };
    }
    case BANKS_SUCCESSFULL: {
      return {
        ...state,
        status: BANKS_SUCCESSFULL,
        banks: action.banks
      };
    }
    case BANKS_ERRORED: {
      return {
        ...state,
        status: BANKS_ERRORED,
        error: action.error
      };
    }
    default:
      return state;
  }
};
