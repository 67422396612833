import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  SvgIcon,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Button
} from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../images/Icons/x-circle.svg';

const styles = theme => ({
  dialogContent: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    textAlign: 'center',
    '& > *': {
      marginBottom: theme.spacing(1)
    }
  },
  dialog: {
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  buttonGroup: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(2)
    }
  }
});

const MailShareDialog = withStyles(styles)(
  ({ classes, open, onClose, onConfirm, mailString, setMailString }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogContent className={classes.dialog}>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <SvgIcon>
            <CloseIcon />
          </SvgIcon>
        </IconButton>
        <div className={classes.dialogContent}>
          <Typography variant="h5" color="primary">
            Escribí el mail de tu amiga
            <br />
            para compartirle el producto:
          </Typography>
          <Typography variant="body2">
            Si son varias direcciones separalas con coma y sin espacios entre
            ellas.
          </Typography>
          <TextField
            type="text"
            value={mailString}
            onChange={({ target: { value } }) => setMailString(value)}
            fullWidth
            onKeyPress={e => {
              if (e.key === 'Enter') {
                onConfirm();
              }
            }}
          />
          <div className={classes.buttonGroup}>
            <Button variant="contained" color="secondary" onClick={onClose}>
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={onConfirm}>
              OK, ENVIAR
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
);

export default MailShareDialog;
