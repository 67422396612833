import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    zIndex: 1
  }
}));

const SpinnerAdornment = () => {
  const classes = useStyles();
  return <CircularProgress className={classes.root} size={20} />;
};

const AdornedButton = props => {
  const { children, loading, ...rest } = props;

  return (
    <Button style={{ position: 'relative' }} {...rest}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  );
};

export default AdornedButton;
