import React from 'react';
import { Typography } from '@material-ui/core';

const NotificationsMenu = () => (
  <div>
    <Typography>Notifications</Typography>
  </div>
);

export default NotificationsMenu;
