import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, ListItem, fade } from '@material-ui/core';

const styles = theme => ({
  divider: {
    borderBottomColor: fade(theme.palette.primary.main, 0.2)
  }
});

const Generic = ({ notification, key, classes, noBorder }) => (
  <ListItem
    key={key}
    divider={!noBorder}
    element="div"
    classes={{
      divider: classes.divider
    }}
  >
    <Typography>
      <Typography style={{ fontWeight: 800, whiteSpace: 'wrap' }}>
        Type:
      </Typography>
      {notification.type}
    </Typography>
  </ListItem>
);

export default withStyles(styles)(Generic);
