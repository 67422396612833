import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Typography,
  TextField,
  List,
  ListItem,
  InputAdornment
} from '@material-ui/core';
import { ChevronRight, Search } from '@material-ui/icons';

import RTVButton from '../RTVButton';
import normalizeString from '../../utils/normalizeString';

const styles = theme => ({
  brandsMenu: {
    padding: theme.spacing(2),
    display: 'flex'
  },
  brandSearch: {
    padding: theme.spacing(1),
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxHeight: '350px',
    overflowY: 'scroll',
    minWidth: '200px'
  },
  listContainer: {
    width: '100%'
  },
  textField: {
    minHeight: '2rem'
  },
  featuredBrands: {
    padding: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: 'auto auto auto'
  },
  showMoreButton: {
    padding: theme.spacing(1),
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2rem'
  },
  buttonTitle: {
    fontWeight: 500
  }
});

class BrandsMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: ''
    };
  }

  setFilter = filter => {
    this.setState({ filter });
  };

  render() {
    const { classes, brands } = this.props;

    const featuredBrands = brands.filter(brand => brand.featured);

    const filterFunction =
      this.state.filter !== ''
        ? brand =>
            brand.name.toUpperCase().includes(this.state.filter.toUpperCase())
        : () => true;

    return (
      <div className={classes.brandsMenu}>
        <div className={classes.brandSearch}>
          <div className={classes.searchBox}>
            <TextField
              variant="outlined"
              size="small"
              value={this.state.filter}
              onChange={({ target: { value } }) => this.setFilter(value)}
              placeholder="Buscar Marca"
              InputProps={{
                classes: {
                  input: classes.input
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          </div>

          <div className={classes.listContainer}>
            <List>
              {brands.filter(filterFunction).map(brand => (
                <ListItem
                  button
                  key={brand.id}
                  onClick={() =>
                    this.props.push(
                      `/productos/marca/${normalizeString(brand.name)}`
                    )
                  }
                  divider
                >
                  <span
                    className={
                      this.state.selected === brand.id ? classes.primary : ''
                    }
                  >
                    {brand.name}
                  </span>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
        <div className={classes.featuredBrands}>
          {featuredBrands.map(brand => (
            <RTVButton
              onClick={() =>
                this.props.push(
                  `/productos/marca/${normalizeString(brand.name)}`
                )
              }
              key={brand.id}
              kind={brand.name.toLowerCase()}
              src={brand.media?.brand_medium}
            >
              {brand.name}
            </RTVButton>
          ))}
          <RTVButton
            variant="custom"
            onClick={() => this.props.push('/productos')}
            style={{
              width: '200px',
              height: '100px',
              maxWidth: '200px',
              maxHeight: '100px'
            }}
            custom={
              <div className={classes.showMoreButton}>
                <Typography className={classes.buttonTitle}>
                  VER TODO
                </Typography>
                <ChevronRight color="primary" />
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  push: path => dispatch(push(path))
});

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(BrandsMenu)
);
