import { useEffect } from 'react';

const onStorageChange = event => {
  if (event.key === 'logout-event') {
    window.location.reload();
  }
};

export default () => {
  useEffect(() => {
    window.addEventListener('storage', onStorageChange);
    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  });

  return '';
};
