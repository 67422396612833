import AbstractRestClient from '../AbstractRestClient';

class CommunicationRestClient extends AbstractRestClient {
  registerToken(token) {
    return this.instance.post(
      `/api/es_ar/communication/pushes/${token}/tokens`
    );
  }

  unregisterToken(token) {
    const messaging = window.firebase && window.firebase.messaging;
    if (typeof messaging === 'function' && messaging.isSupported()) {
      messaging().deleteToken();
    }
    return this.instance.delete(
      `/api/es_ar/communication/pushes/${token}/token`
    );
  }
}

export default new CommunicationRestClient();
