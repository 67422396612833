import AbstractRestClient from '../AbstractRestClient';
import store from '../../redux/store';

class FavouriteRestClient extends AbstractRestClient {
  getFavouriteList(user) {
    return this.instance.get(`/api/es_ar/users/${user}/favourite/list`);
  }

  getFavourites(user, offset = 0, pageSize = 10) {
    return this.instance.get(
      `/api/es_ar/users/${user}/favourite?offset=${offset}&pageSize=${pageSize}`
    );
  }

  like(product) {
    return this.instance.post(
      `/api/es_ar/users/${store.getState().auth.user.id}/favourites/${
        product.id
      }/products`
    );
  }

  unlike(product) {
    return this.instance.delete(
      `/api/es_ar/users/${store.getState().auth.user.id}/favourites/${
        product.id
      }/product`
    );
  }
}

export default new FavouriteRestClient();
