import { useEffect, useState } from 'react';
import axiosInstance from '../http/axiosConfig';

const useSavedWaterCounter = now => {
  const SECONDS = 2;
  const [totalWater, setTotalWater] = useState(0);
  const [hourlyWater, setHourlyWater] = useState(0);
  const [waterToAdd, setWaterToAdd] = useState(0);
  const [counterWater, setCounterWater] = useState(999999999);

  useEffect(() => {
    setTimeout(() => {
      axiosInstance.get('/api/es_ar/home/water').then(response => {
        setTotalWater(response.data.total);
        setHourlyWater(response.data.hourly);
        let secondsSinceLastHourlyAmount = Math.floor(
          (now - new Date(response.data.moment.date).getTime()) /
            (1000 * SECONDS)
        );
        if (response.data.hourly >= 3600 / SECONDS) {
          setWaterToAdd(Math.floor(response.data.hourly / (3600 / SECONDS)));
          setCounterWater(
            response.data.total +
              secondsSinceLastHourlyAmount *
                Math.floor(response.data.hourly / (3600 / SECONDS))
          );
        } else {
          setCounterWater(response.data.hourly + response.data.total);
        }
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    totalWater,
    hourlyWater,
    waterToAdd,
    counterWater,
    setCounterWater,
    SECONDS
  ];
};

export default useSavedWaterCounter;
