import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import DefaultAvatar from '../images/default_avatar.png';
import RoleIcon from './RoleIcon';

const RTVAvatar = props =>
  props.user ? (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      badgeContent={<RoleIcon user={props.user} />}
    >
      <Avatar {...props}>
        <img
          style={{ width: '110%' }}
          alt="default_avatar"
          src={DefaultAvatar}
        />
      </Avatar>
    </Badge>
  ) : (
    <Avatar {...props}>
      <img style={{ width: '110%' }} alt="default_avatar" src={DefaultAvatar} />
    </Avatar>
  );

export default RTVAvatar;
