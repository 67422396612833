/**
 * TODO refactor: Review throughly the component and its usage to prevent
 * the need for any hack or weird behaviour.
 * My Theory is that there's something with the initial state fo the carousel
 * when there's no items and where there are, it makes it scroll all the way
 * to the right.
 */
import React, { Component } from 'react';
import lodash from 'lodash';
import smoothscroll from 'smoothscroll-polyfill';
import { Fab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '100%',
    width: '100%',
    '& ::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  itemContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    width: '100%',
    scrollSnapType: 'x mandatory',
    '& > *': {
      scrollSnapAlign: 'end'
    }
  },
  sliderButton: {
    zIndex: 3,
    background: 'white',
    minWidth: '36px',
    width: '36px',
    height: '36px',
    opacity: 0.9
  }
});

class Slider extends Component {
  constructor(props) {
    super();

    this.debouncedHandler = lodash.debounce(this.handler, 50);

    this.state = {
      scrollAmount: props.scrollAmount || 254,
      leftScroll: false,
      rightScroll: false,
      paddingHack: false
    };
  }

  componentDidMount() {
    // Ensure the slider starts at the correct position
    setTimeout(() => {
      if (this.slider) {
        this.slider.scrollLeft = 0;
      }
      this.handler();
    }, 100);
    smoothscroll.polyfill();
  }

  componentDidUpdate(prevProps) {
    if (
      React.Children.count(prevProps.children) !==
      React.Children.count(this.props.children)
    ) {
      this.handler();
    }
  }

  componentWillUnmount() {
    this.timeouts.forEach(timeout => clearTimeout(timeout));
    this.slider.removeEventListener('scroll', this.debouncedHandler);
    window.removeEventListener('resize', this.debouncedHandler);
  }

  handler = () => {
    if (!this.slider) {
      return;
    }

    const scrollDifference = this.slider.scrollWidth - this.slider.offsetWidth;
    this.setState({
      leftScroll: this.slider.scrollLeft,
      rightScroll: this.slider.scrollLeft !== scrollDifference
    });
  };

  setSliderRef = slider => {
    if (!slider) {
      return;
    }

    this.slider = slider;

    this.handler();
    // Although we try to check immediately if we should scroll
    // We set a timeout to check a couple times to have the initial status correctly.
    this.timeouts = [
      setTimeout(this.handler, 1000),
      setTimeout(this.handler, 5000),
      setTimeout(this.handler, 10000)
    ];

    window.addEventListener('resize', this.debouncedHandler);
    this.slider.addEventListener('scroll', this.debouncedHandler);
  };

  slide = direction => {
    if (!this.slider) {
      return;
    }
    this.slider.scrollBy({
      left: this.state.scrollAmount * direction,
      top: 0,
      behavior: 'smooth'
    });
  };

  togglePaddingHack = toggle => {
    this.setState(state => ({
      paddingHack: toggle
        ? {
            overflowY: 'visible',
            paddingBottom: '80px',
            marginBottom: '-80px',
            zIndex: 2
          }
        : {}
    }));
  };

  render() {
    const { classes, children } = this.props;

    return (
      <div className={classes.wrapper}>
        <Fab
          size="small"
          className={classes.sliderButton}
          onClick={() => this.slide(-1)}
          style={{
            display: this.state.leftScroll ? 'block' : 'none',
            marginRight: '-36px'
          }}
        >
          &#10094;
        </Fab>
        <div
          ref={this.setSliderRef}
          style={{ ...this.state.paddingHack }}
          className={classes.itemContainer}
        >
          {this.props.productSlider
            ? React.Children.map(children, child =>
                React.cloneElement(
                  child,
                  {
                    togglePaddingHack: this.togglePaddingHack
                  },
                  null
                )
              )
            : children}
        </div>
        <Fab
          size="small"
          className={classes.sliderButton}
          onClick={() => this.slide(1)}
          style={{
            display: this.state.rightScroll ? 'block' : 'none',
            marginLeft: '-36px'
          }}
        >
          &#10095;
        </Fab>
      </div>
    );
  }
}

export default withStyles(styles)(Slider);
