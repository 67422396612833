import {
  LIKE_PRODUCT_REQUESTED,
  LIKE_PRODUCT_SUCCESSFULL,
  LIKE_PRODUCT_FAILED,
  UNLIKE_PRODUCT_REQUESTED,
  UNLIKE_PRODUCT_SUCCESSFULL,
  UNLIKE_PRODUCT_FAILED,
  FAVOURITE_LIST_REQUESTED,
  FAVOURITE_LIST_RETRIEVED,
  FAVOURITE_LIST_FAILED
} from './types';
import FavouriteRestClient from '../../http/client/Favourite';
import Analytics from '../../services/Analytics';

export const getFavouriteList = user => {
  const requested = () => ({ type: FAVOURITE_LIST_REQUESTED });
  const successfull = response => ({
    type: FAVOURITE_LIST_RETRIEVED,
    favourites: response.data
  });
  const errored = error => ({
    type: FAVOURITE_LIST_FAILED,
    error
  });

  return dispatch => {
    dispatch(requested());

    return FavouriteRestClient.getFavouriteList(user)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const like = product => {
  const requested = () => ({
    type: LIKE_PRODUCT_REQUESTED,
    product: product.id
  });
  const successfull = () => ({
    type: LIKE_PRODUCT_SUCCESSFULL,
    product: product.id
  });
  const errored = error => ({
    type: LIKE_PRODUCT_FAILED,
    product: product.id,
    error
  });

  return dispatch => {
    dispatch(requested());

    return FavouriteRestClient.like(product)
      .then(() => {
        Analytics.favourite(product);
        dispatch(successfull());
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const unlike = product => {
  const requested = () => ({
    type: UNLIKE_PRODUCT_REQUESTED,
    product: product.id
  });
  const successfull = () => ({
    type: UNLIKE_PRODUCT_SUCCESSFULL,
    product: product.id
  });
  const errored = error => ({
    type: UNLIKE_PRODUCT_FAILED,
    error,
    product: product.id
  });

  return dispatch => {
    dispatch(requested());

    return FavouriteRestClient.unlike(product)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};
