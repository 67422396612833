import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import welcomeStyles from './welcomeStyles';

const WelcomeShowroom = ({ classes }) => (
  <>
    <div className={classes.welcomeMessage}>
      <Typography
        color="primary"
        variant="h3"
        style={{ fontWeight: 800, marginBottom: 0 }}
      >
        SOLICITASTE TU SHOWROOM!
      </Typography>
      <Typography
        variant="h5"
        component="h3"
        style={{ fontWeight: 500, marginTop: 0 }}
      >
        Vamos a confirmar tu cuenta dentro de 48 hrs hábiles
      </Typography>
      <Button
        color="primary"
        variant="contained"
        component={Link}
        to="/como-funciona"
      >
        <Info style={{ marginRight: '8px' }} />
        Como funciona?
      </Button>
    </div>
  </>
);

export default withStyles(welcomeStyles)(WelcomeShowroom);
