import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  SvgIcon,
  ListItemSecondaryAction
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { ReactComponent as BankTransferIcon } from '../../images/Icons/banktransfers-01.svg';

const styles = theme => ({
  primaryText: {
    fontSize: '1.2rem',
    divider: {
      borderBottomColor: fade(theme.palette.primary.main, 0.2)
    },
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      fontSize: '1.2rem'
    },
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column',
      alignItems: 'flex-start',
      '& > p': {
        marginLeft: '0 !important'
      }
    }
  },
  textLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  avatar: {
    backgroundColor: '#fff',
    width: '60px',
    height: '60px',
    boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.06)',
    '& > svg': {
      width: '50px',
      height: '50px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '56px',
      height: '56px'
    }
  },
  listAvatar: {
    marginRight: theme.spacing(1)
  }
});

const PaymentPending = ({ notification, key, classes, noBorder }) => (
  <ListItem
    key={key}
    alignItems="flex-start"
    divider={!noBorder}
    classes={{
      divider: classes.divider
    }}
  >
    <ListItemAvatar className={classes.listAvatar}>
      <Avatar className={classes.avatar} alt="avatar">
        <SvgIcon color="primary">
          <BankTransferIcon />
        </SvgIcon>
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={
        <Typography component="span" className={classes.primaryText}>
          Tenés un pago pendiente!
          <Typography style={{ marginLeft: '4px', fontWeight: 400 }}>
            · Pedido XXXX
          </Typography>
        </Typography>
      }
      secondary={notification.timePassedString}
    />
    <ListItemSecondaryAction>
      <ChevronRight
        style={{ cursor: 'pointer', width: '40px', height: '40px' }}
        color="primary"
      />
    </ListItemSecondaryAction>
  </ListItem>
);

export default withStyles(styles)(PaymentPending);
