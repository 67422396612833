import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  IconButton
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import RTVAvatar from '../RTVAvatar';

const styles = theme => ({
  primaryText: {
    textDecoration: 'none',
    '& > .divider': {
      borderBottomColor: fade(theme.palette.primary.main, 0.2)
    },
    display: 'inline',
    fontWeight: 500
  },
  textLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  feedText: {
    maxWidth: '220px'
  },
  body: {
    textDecoration: 'none'
  }
});

const ContractNotification = ({
  notification,
  noBorder,
  type,
  classes,
  ...props
}) => {
  if (typeof notification.title !== 'string') {
    // eslint-disable-next-line
    notification.title = '';
  }

  let headParameter = '';
  let actionParameter = '';
  if (
    type === 'feed' &&
    notification.feed &&
    notification.feed.actionParameter !== null
  ) {
    if (notification.feed.headParameter) {
      headParameter = notification.feed.headParameter;
      actionParameter = notification.feed.actionParameter;
    } else {
      headParameter = notification.feed.actionParameter;
      actionParameter = notification.feed.actionParameter;
    }
  } else if (notification.actionParameter !== null) {
    if (notification.headParameter) {
      headParameter = notification.headParameter;
      actionParameter = notification.actionParameter;
    } else {
      headParameter = notification.actionParameter;
      actionParameter = notification.actionParameter;
    }
  }

  return (
    <ListItem
      alignItems="flex-start"
      divider={!noBorder}
      classes={{
        divider: classes.divider
      }}
      ContainerProps={props}
    >
      <ListItemAvatar>
        <RTVAvatar
          src={
            type === 'feed' && notification.feed && notification.feed.image
              ? notification.feed.image
              : notification.image
          }
          component={`${headParameter !== '' ? 'a' : ''}`}
          href={headParameter}
          alt="avatar"
        />
      </ListItemAvatar>
      <ListItemText
        classes={{ multiline: type === 'feed' ? classes.feedText : undefined }}
        primary={
          <Typography
            className={classes.primaryText}
            component={`${actionParameter !== '' ? 'a' : ''}`}
            href={actionParameter}
          >
            {type === 'feed' && notification.feed
              ? notification.feed.title
              : notification.title}
            &nbsp;
            {type !== 'feed' && (
              <Typography
                className={classes.body}
                style={{ color: notification.bodyColor }}
                component={`${actionParameter !== '' ? 'a' : ''}`}
                href={actionParameter}
              >
                {' '}
                {notification.body}
              </Typography>
            )}
          </Typography>
        }
        secondary={
          <>
            {type === 'feed' && (
              <Typography
                style={{
                  textDecoration: 'none',
                  color: notification.bodyColor,
                  display: 'block'
                }}
                component={`${actionParameter !== '' ? 'a' : ''}`}
                href={actionParameter}
              >
                {' '}
                {notification.feed ? notification.feed.body : notification.body}
              </Typography>
            )}
            {notification.timePassedString} {notification.dateAppend}
          </>
        }
      />
      {type !== 'feed' && (
        <ListItemSecondaryAction>
          {notification.action === 'IMAGE' && notification.actionImage && (
            <a href={notification.actionParameter}>
              <RTVAvatar
                src={notification.actionImage}
                alt="imagen de notificacion"
                component={`${actionParameter !== '' ? 'a' : ''}`}
                href={actionParameter}
              />
            </a>
          )}
          {notification.action === 'ARROW' && (
            <IconButton component="a" href={notification.actionParameter}>
              <ChevronRight color="primary" />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default withStyles(styles)(ContractNotification);
