import {
  REGISTER_PUSH_TOKEN_REQUESTED,
  REGISTER_PUSH_TOKEN_SUCCESSFULL,
  REGISTER_PUSH_TOKEN_ERRORED,
  UNREGISTER_PUSH_TOKEN_REQUESTED,
  UNREGISTER_PUSH_TOKEN_SUCCESSFULL,
  UNREGISTER_PUSH_TOKEN_ERRORED
} from '../actions/types';

const defaultState = {
  status: null,
  token: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_PUSH_TOKEN_REQUESTED: {
      return {
        ...state,
        status: REGISTER_PUSH_TOKEN_REQUESTED
      };
    }
    case REGISTER_PUSH_TOKEN_SUCCESSFULL: {
      return {
        ...state,
        status: REGISTER_PUSH_TOKEN_SUCCESSFULL,
        token: action.token
      };
    }
    case REGISTER_PUSH_TOKEN_ERRORED: {
      return {
        ...state,
        status: REGISTER_PUSH_TOKEN_ERRORED
      };
    }
    case UNREGISTER_PUSH_TOKEN_REQUESTED: {
      return {
        ...state,
        status: UNREGISTER_PUSH_TOKEN_REQUESTED
      };
    }
    case UNREGISTER_PUSH_TOKEN_SUCCESSFULL: {
      return {
        ...state,
        status: UNREGISTER_PUSH_TOKEN_SUCCESSFULL,
        token: null
      };
    }
    case UNREGISTER_PUSH_TOKEN_ERRORED: {
      return {
        ...state,
        status: UNREGISTER_PUSH_TOKEN_ERRORED
      };
    }
    default:
      return state;
  }
};
