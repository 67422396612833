import confettiGif from '../../images/confetti.gif';

export default theme => ({
  buttonGroup: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  welcomeMessage: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(3),
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${confettiGif})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#f7ece2',
    '& > *': {
      textAlign: 'center',
      margin: theme.spacing(2)
    }
  }
});
