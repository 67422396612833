import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as Logo } from '../../images/hanger.svg';
import './animation.scss';

const RTVLoadingHanger = ({ fullScreen, message, ...props }) => {
  if (fullScreen) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexFlow: 'column'
        }}
      >
        <Logo className="hanger-movement" width="100px" height="100px" />
        {message !== undefined && (
          <Typography style={{ fontWeight: 600, fontSize: '1.1rem' }}>
            {message}
          </Typography>
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Logo
        className="hanger-movement"
        width="100px"
        height="100px"
        {...props}
      />
      {message !== undefined && (
        <Typography style={{ fontWeight: 600, fontSize: '1.1rem' }}>
          {message}
        </Typography>
      )}
    </div>
  );
};

export default RTVLoadingHanger;
