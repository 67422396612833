export default number => {
  if (number && typeof number === 'string') {
    number = number.replace(/^[a-zA-Z!?]*$/, '');
    number = parseFloat(number, 10);
  }
  if (!number) {
    return '0';
  }
  return Math.abs(number) % 1 > 0
    ? number
        .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    : number
        .toString()
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
