import AbstractRestClient from '../AbstractRestClient';
import store from '../../redux/store';

class SocialRestClient extends AbstractRestClient {
  getUser = () => store.getState().auth.user.id;

  getFollowList(user) {
    return this.instance.get(`/api/es_ar/followings/${user}/list`);
  }

  follow(user) {
    return this.instance.post(`/api/es_ar/follows/${user}`);
  }

  unfollow(user) {
    return this.instance.delete(`/api/es_ar/follows/${user}`);
  }

  followings(user, filterPromo = false, page = 1, pageSize = 10) {
    return this.instance.get(
      `/api/es_ar/followings/${user}?page=${page}&pageSize=${pageSize}&promotion=${
        filterPromo ? 1 : 0
      }`
    );
  }

  activeFollowings(page, pageSize) {
    const user = this.getUser();
    return this.instance.get(
      `/api/es_ar/users/${user}/following/active?page=${page}&pageSize=${pageSize}`
    );
  }

  followers(user, page = 1, pageSize = 10) {
    return this.instance.get(
      `/api/es_ar/followers/${user}?page=${page}&pageSize=${pageSize}`
    );
  }

  feed(offset = 0) {
    return this.instance.get(`/api/es_ar/social/feed?offset=${offset}`);
  }

  getUserNotifications(type = 'ALL', offset, pageSize = 10) {
    const user = this.getUser();
    return this.instance.get(
      `/api/es_ar/communication/users/${user}/notifications?as=${type}&offset=${offset}&pageSize=${pageSize}`
    );
  }

  readNotification = notification =>
    this.instance.post(
      `/api/es_ar/communication/notifications/${notification}/seens`
    );
}

export default new SocialRestClient();
