import React from 'react';
import * as DateService from '../../services/DateService';
import GenericNotification from './Generic';
import ProductLike from './ProductLike';
import NewFollower from './NewFollower';
import MyPromotion from './MyPromotion';
import PaymentPending from './PaymentPending';
import Welcome from './Welcome';
import NewParentComment from './NewParentComment';

import ContractNotification from './ContractNotification';

const NotificationFactory = (
  notification,
  type = 'feed',
  noBorder = false,
  props
) => {
  if (!notification) {
    return '';
  }

  let timePassedString = 'Hace ';

  if (notification.date) {
    timePassedString += DateService.getDayDiffString(notification.date);
  }

  // eslint-disable-next-line no-param-reassign
  notification.timePassedString = notification.date ? timePassedString : null;

  if (notification.type === 'CONTRACT') {
    return (
      <ContractNotification
        type={type}
        key={notification.id}
        noBorder={noBorder}
        notification={notification}
        {...props}
      />
    );
  }

  switch (notification.type) {
    case 'App\\Entity\\Notifications\\ProductLike': {
      return React.createElement(ProductLike, { notification, type, noBorder });
    }
    case 'App\\Entity\\Notifications\\NewFollower': {
      return React.createElement(NewFollower, { notification, type, noBorder });
    }
    case 'App\\Entity\\Notifications\\MyPromotion': {
      return React.createElement(MyPromotion, { notification, type, noBorder });
    }
    case 'App\\Entity\\Notifications\\PromotionUserFollowed': {
      return React.createElement(MyPromotion, { notification, type, noBorder });
    }
    case 'App\\Entity\\Notifications\\PaymentPending': {
      return React.createElement(PaymentPending, {
        notification,
        type,
        noBorder
      });
    }
    case 'App\\Entity\\Notifications\\Welcome': {
      return React.createElement(Welcome, { notification, type, noBorder });
    }
    case 'App\\Entity\\Notifications\\NewParentComment': {
      return React.createElement(NewParentComment, {
        notification,
        type,
        noBorder
      });
    }
    default:
      return React.createElement(GenericNotification, {
        notification,
        noBorder
      });
  }
};

export default NotificationFactory;
