import {
  PRODUCT_COMMENTS_REQUESTED,
  PRODUCT_COMMENTS_RETRIEVED,
  PRODUCT_COMMENTS_FAILED,
  PARENT_COMMENT_POST_REQUESTED,
  PARENT_COMMENT_POST_SUCCESSFULL,
  PARENT_COMMENT_POST_ERRORED,
  CHILD_COMMENT_POST_REQUESTED,
  CHILD_COMMENT_POST_SUCCESSFULL,
  CHILD_COMMENT_POST_ERRORED,
  SEEN_SELLER_COMMENTS_REQUESTED,
  SEEN_SELLER_COMMENTS_RETRIEVED,
  SEEN_SELLER_COMMENTS_FAILED,
  UNSEEN_SELLER_COMMENTS_REQUESTED,
  UNSEEN_SELLER_COMMENTS_RETRIEVED,
  UNSEEN_SELLER_COMMENTS_FAILED,
  SEEN_BUYER_COMMENTS_REQUESTED,
  SEEN_BUYER_COMMENTS_RETRIEVED,
  SEEN_BUYER_COMMENTS_FAILED,
  UNSEEN_BUYER_COMMENTS_REQUESTED,
  UNSEEN_BUYER_COMMENTS_RETRIEVED,
  UNSEEN_BUYER_COMMENTS_FAILED,
  COMMENT_REQUESTED,
  COMMENT_RETRIEVED,
  COMMENT_FAILED,
  MARK_COMMENT_SEEN_REQUESTED,
  MARK_COMMENT_SEEN_SUCCESSFULL,
  MARK_COMMENT_SEEN_FAILED
} from '../actions/types';

const defaultState = {
  comments: [],

  currentProduct: null,
  hasMoreComments: false,

  seenBuyerComments: [],
  unseenBuyerComments: [],
  seenSellerComments: [],
  unseenSellerComments: [],

  hasMoreSeenSeller: true,
  hasMoreUnseenSeller: true,
  hasMoreSeenBuyer: true,
  hasMoreUnseenBuyer: true,

  parentComment: null,

  unseenBuyer: 0,
  seenBuyer: 0,
  unseenSeller: 0,
  seenSeller: 0,

  conversations: [],
  newComment: null,

  error: null,
  status: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PRODUCT_COMMENTS_REQUESTED: {
      return {
        ...state,
        status: PRODUCT_COMMENTS_REQUESTED,
        error: null,
        comments: action.offset === 0 ? [] : state.comments
      };
    }
    case PRODUCT_COMMENTS_RETRIEVED: {
      return {
        ...state,
        status: PRODUCT_COMMENTS_RETRIEVED,
        comments: [...state.comments, ...action.comments],
        hasMoreComments: action.hasMoreComments,
        currentProduct: action.product
      };
    }
    case PRODUCT_COMMENTS_FAILED: {
      return {
        ...state,
        status: PRODUCT_COMMENTS_FAILED,
        error: action.error
      };
    }
    case PARENT_COMMENT_POST_REQUESTED: {
      return {
        ...state,
        status: PARENT_COMMENT_POST_REQUESTED
      };
    }
    case PARENT_COMMENT_POST_SUCCESSFULL: {
      const comments = state.comments || [];
      const [latestComment, ...restOfComments] = comments;

      if (
        latestComment &&
        latestComment.author.id === action.comment.author.id
      ) {
        const children = latestComment.child_comments || [];
        return {
          ...state,
          status: PARENT_COMMENT_POST_SUCCESSFULL,
          comments: [
            {
              ...latestComment,
              child_comments: [...children, action.comment]
            },
            ...restOfComments
          ]
        };
      }
      return {
        ...state,
        status: PARENT_COMMENT_POST_SUCCESSFULL,
        comments: [action.comment, ...restOfComments]
      };
    }
    case PARENT_COMMENT_POST_ERRORED: {
      return {
        ...state,
        status: PARENT_COMMENT_POST_ERRORED,
        error: action.error
      };
    }
    case SEEN_SELLER_COMMENTS_REQUESTED: {
      return {
        ...state,
        status: SEEN_SELLER_COMMENTS_REQUESTED,
        hasMoreSeenSeller: true
      };
    }
    case SEEN_SELLER_COMMENTS_RETRIEVED: {
      return {
        ...state,
        status: SEEN_SELLER_COMMENTS_RETRIEVED,
        seenSellerComments: action.sellerComments.comments,
        seenSeller: Number(action.sellerComments.totals.seen),
        unseenSeller: Number(action.sellerComments.totals.unseen),
        hasMoreSeenSeller: action.sellerComments.comments.length === 10
      };
    }
    case SEEN_SELLER_COMMENTS_FAILED: {
      return {
        ...state,
        status: SEEN_SELLER_COMMENTS_FAILED,
        hasMoreSeenSeller: false
      };
    }
    case UNSEEN_SELLER_COMMENTS_REQUESTED: {
      return {
        ...state,
        status: UNSEEN_SELLER_COMMENTS_REQUESTED,
        hasMoreUnseenSeller: true
      };
    }
    case UNSEEN_SELLER_COMMENTS_RETRIEVED: {
      return {
        ...state,
        status: UNSEEN_SELLER_COMMENTS_RETRIEVED,
        unseenSellerComments: action.sellerComments.comments,
        seenSeller: Number(action.sellerComments.totals.seen),
        unseenSeller: Number(action.sellerComments.totals.unseen),
        hasMoreUnseenSeller: action.sellerComments.comments.length === 10
      };
    }
    case UNSEEN_SELLER_COMMENTS_FAILED: {
      return {
        ...state,
        status: UNSEEN_SELLER_COMMENTS_FAILED,
        hasMoreUnseenSeller: false
      };
    }
    case SEEN_BUYER_COMMENTS_REQUESTED: {
      return {
        ...state,
        status: SEEN_BUYER_COMMENTS_REQUESTED,
        hasMoreSeenBuyer: true
      };
    }
    case SEEN_BUYER_COMMENTS_RETRIEVED: {
      return {
        ...state,
        status: SEEN_BUYER_COMMENTS_RETRIEVED,
        seenBuyerComments: action.buyerComments.comments,
        unseenBuyer: Number(action.buyerComments.totals.unseen),
        hasMoreSeenBuyer: action.buyerComments.comments.length === 10
      };
    }
    case SEEN_BUYER_COMMENTS_FAILED: {
      return {
        ...state,
        status: SEEN_BUYER_COMMENTS_FAILED,
        hasMoreSeenBuyer: false
      };
    }
    case UNSEEN_BUYER_COMMENTS_REQUESTED: {
      return {
        ...state,
        status: UNSEEN_BUYER_COMMENTS_REQUESTED,
        hasMoreUnseenBuyer: true
      };
    }
    case UNSEEN_BUYER_COMMENTS_RETRIEVED: {
      return {
        ...state,
        status: UNSEEN_BUYER_COMMENTS_RETRIEVED,
        unseenBuyerComments: action.buyerComments.comments,
        seenBuyer: Number(action.buyerComments.totals.seen),
        unseenBuyer: Number(action.buyerComments.totals.unseen),
        hasMoreUnseenBuyer: action.buyerComments.comments.length === 10
      };
    }
    case UNSEEN_BUYER_COMMENTS_FAILED: {
      return {
        ...state,
        status: UNSEEN_BUYER_COMMENTS_FAILED,
        hasMoreUnseenBuyer: false
      };
    }
    case COMMENT_REQUESTED: {
      return {
        ...state,
        status: COMMENT_REQUESTED
      };
    }
    case COMMENT_RETRIEVED: {
      const { child_comments, ...parentComment } = action.conversation;
      return {
        ...state,
        status: COMMENT_RETRIEVED,
        conversation: [{ ...parentComment }, ...child_comments]
      };
    }
    case COMMENT_FAILED: {
      return {
        ...state,
        status: COMMENT_FAILED
      };
    }
    case CHILD_COMMENT_POST_REQUESTED: {
      return {
        ...state,
        status: CHILD_COMMENT_POST_REQUESTED
      };
    }
    case CHILD_COMMENT_POST_SUCCESSFULL: {
      return {
        ...state,
        status: CHILD_COMMENT_POST_SUCCESSFULL,
        conversation: [...state.conversation, action.comment],
        newComment: action.comment,
        parentComment: action.parentComment
      };
    }
    case CHILD_COMMENT_POST_ERRORED: {
      return {
        ...state,
        status: CHILD_COMMENT_POST_ERRORED,
        error: action.error
      };
    }
    case MARK_COMMENT_SEEN_REQUESTED: {
      return {
        ...state,
        status: MARK_COMMENT_SEEN_REQUESTED
      };
    }
    case MARK_COMMENT_SEEN_SUCCESSFULL: {
      return {
        ...state,
        status: MARK_COMMENT_SEEN_SUCCESSFULL,
        parentComment: action.parentComment
      };
    }
    case MARK_COMMENT_SEEN_FAILED: {
      return {
        ...state,
        status: MARK_COMMENT_SEEN_FAILED,
        error: action.error
      };
    }
    default:
      return state;
  }
};
