import React from 'react';
import LoadingGif from '../../images/Loading.gif';
// eslint-disable-next-line
import MercadoPago from '../../http/Mercadopago';

const MobileLoading = () => {
  React.useEffect(() => {
    setTimeout(() => {
      if (!window.mobileInjectioncheck) {
        window.location.replace('/home');
      }
    }, 15000);
  });
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <img src={LoadingGif} alt="Loading..." />
    </div>
  );
};

export default MobileLoading;
