class MercadopagoSingleton {
  constructor() {
    if (typeof this.instance === 'object') {
      return this.instance;
    }

    this.instance = new window.MercadoPago(
      process.env.REACT_APP_MERCADOPAGO_API
    );
    return this.instance;
  }
}

export default new MercadopagoSingleton();
