import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import RTVAvatar from '../RTVAvatar';
import RTVFollowButton from '../RTVFollowButton';
import AdornedButton from '../AdornedButton';

const styles = theme => ({
  primaryText: {
    fontSize: '1.2rem',
    divider: {
      borderBottomColor: fade(theme.palette.primary.main, 0.2)
    },
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      fontSize: '1.2rem'
    },
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column',
      alignItems: 'flex-start',
      '& > p': {
        marginLeft: '0 !important'
      }
    }
  },
  textLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  avatar: {
    width: '60px',
    height: '60px',
    boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.06)',
    [theme.breakpoints.down('xs')]: {
      width: '56px',
      height: '56px'
    }
  },
  listAvatar: {
    marginRight: theme.spacing(1)
  }
});

const NewFollower = ({ notification, key, classes, type, noBorder }) => (
  <ListItem
    key={key}
    alignItems="flex-start"
    divider={!noBorder}
    classes={{
      divider: classes.divider
    }}
  >
    {type === 'feed' && (
      <>
        <ListItemAvatar>
          <Link to={`/vestidor/${notification.follower.username}`}>
            <RTVAvatar
              src={
                notification.follower.media
                  ? notification.follower.media.default_small
                  : null
              }
              alt="avatar"
            />
          </Link>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.primaryText}>
              <Link
                to={`/vestidor/${notification.follower.username}`}
                className={classes.textLink}
              >
                {notification.follower.username}
              </Link>
            </Typography>
          }
          secondary={
            <>
              <Typography color="textPrimary">
                empezó a seguir a{' '}
                <Link
                  to={`/vestidor/${notification.follower.username}`}
                  className={classes.textLink}
                >
                  {notification.user.username}
                </Link>
              </Typography>
              {notification.timePassedString}
            </>
          }
        />
      </>
    )}
    {type !== 'feed' && (
      <>
        <ListItemAvatar className={classes.listAvatar}>
          <Link to={`/vestidor/${notification.follower.username}`}>
            <RTVAvatar
              className={classes.avatar}
              src={
                notification.follower.media
                  ? notification.follower.media.default_small
                  : null
              }
              alt="avatar"
            />
          </Link>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography component="span" className={classes.primaryText}>
              <Link
                to={`/vestidor/${notification.follower.username}`}
                className={classes.textLink}
              >
                {notification.follower.username}
              </Link>
              <Typography style={{ fontWeight: 400, marginLeft: '4px' }}>
                te empezó a seguir!
              </Typography>
            </Typography>
          }
          secondary={notification.timePassedString}
        />
        <ListItemSecondaryAction>
          <RTVFollowButton user={notification.follower.id}>
            {followProps => (
              <AdornedButton
                className={classes.followButton}
                variant="contained"
                disabled={followProps.loading}
                onClick={followProps.onClick}
                loading={followProps.loading}
                color={followProps.following ? 'secondary' : 'primary'}
              >
                {followProps.following ? 'Siguiendo' : 'Seguir'}
              </AdornedButton>
            )}
          </RTVFollowButton>
        </ListItemSecondaryAction>
      </>
    )}
  </ListItem>
);

export default withStyles(styles)(NewFollower);
