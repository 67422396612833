import {
  STATE_REGISTERING,
  STATE_WELCOME,
  STATE_APP,
  SW_UPDATE,
  SW_UPDATE_FINISHED,
  SW_INIT,
  HEART_BEAT_RETRIEVED,
  SET_FROM_URL
} from '../actions/types';

const defaultState = {
  state: STATE_REGISTERING,
  swUpdate: false,
  swReg: null,
  maintainance: false,
  fromURL: ''
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case STATE_REGISTERING:
      return {
        ...state,
        state: STATE_REGISTERING
      };
    case STATE_WELCOME:
      return {
        ...state,
        state: STATE_WELCOME
      };
    case STATE_APP:
      return {
        ...state,
        state: STATE_APP
      };
    case SW_UPDATE:
      return {
        ...state,
        swUpdate: true,
        swReg: action.payload
      };
    case SW_UPDATE_FINISHED:
      return {
        ...state,
        swUpdate: false
      };
    case SW_INIT:
      return {
        ...state,
        swReg: action.payload
      };
    case HEART_BEAT_RETRIEVED:
      return {
        ...state,
        maintainance: action.heartbeat['maintainance-web']
      };
    case SET_FROM_URL:
      return {
        ...state,
        fromURL: action.url
      };
    default:
      return state;
  }
};
