import {
  FOLLOW_REQUESTED,
  FOLLOW_SUCCEED,
  FOLLOW_FAILED,
  UNFOLLOW_REQUESTED,
  UNFOLLOW_SUCCEED,
  UNFOLLOW_FAILED,
  FOLLOWERS_REQUESTED,
  FOLLOWERS_RETRIEVED,
  FOLLOWERS_FAILED,
  FOLLOWINGS_REQUESTED,
  FOLLOWINGS_RETRIEVED,
  FOLLOWINGS_FAILED,
  SOCIAL_FEED_REQUESTED,
  SOCIAL_FEED_RETRIEVED,
  SOCIAL_FEED_FAILED,
  USER_NOTIFICATIONS_FAILED,
  USER_NOTIFICATIONS_REQUESTED,
  USER_NOTIFICATIONS_RETRIEVED,
  NOTIFICATION_READ_REQUESTED,
  NOTIFICATION_READ_SUCCESFULL,
  NOTIFICATION_READ_ERRORED,
  ACTIVE_FOLLOWINGS_REQUESTED,
  ACTIVE_FOLLOWINGS_SUCCESSFULL,
  ACTIVE_FOLLOWINGS_ERRORED
} from '../actions/types';

const defaultState = {
  status: null,
  error: null,
  followers: [],
  followings: [],
  activeFollowings: [],
  followState: {
    user: '',
    following: false,
    error: false
  },
  feed: [],
  notifications: {
    all: [],
    buying: [],
    selling: []
  },
  notificationType: '',
  hasMoreNotifications: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FOLLOW_REQUESTED: {
      return {
        ...state,
        user: action.user,
        followState: { user: '', error: false },
        status: FOLLOW_REQUESTED
      };
    }
    case FOLLOW_SUCCEED: {
      return {
        ...state,
        followState: { user: action.user, following: true, error: false },
        status: FOLLOW_SUCCEED
      };
    }
    case FOLLOW_FAILED: {
      return {
        ...state,
        error: action.error,
        followState:
          action.error.status === 409
            ? { user: action.user, following: true, error: true }
            : { user: action.user, following: false, error: true },
        status: FOLLOW_FAILED
      };
    }
    case UNFOLLOW_REQUESTED: {
      return {
        ...state,
        followState: { user: '', error: false },
        status: UNFOLLOW_REQUESTED
      };
    }
    case UNFOLLOW_SUCCEED: {
      return {
        ...state,
        followState: { user: action.user, following: false, error: false },
        status: UNFOLLOW_SUCCEED
      };
    }
    case UNFOLLOW_FAILED: {
      return {
        ...state,
        error: action.error,
        followState:
          action.error.status === 409
            ? { user: action.user, following: false, error: true }
            : { user: action.user, following: true, error: true },
        status: UNFOLLOW_FAILED
      };
    }
    case FOLLOWERS_REQUESTED: {
      return {
        ...state,
        status: FOLLOWERS_REQUESTED
      };
    }
    case FOLLOWERS_RETRIEVED: {
      return {
        ...state,
        status: FOLLOWERS_RETRIEVED,
        followers: action.followers
      };
    }
    case FOLLOWERS_FAILED: {
      return {
        ...state,
        status: FOLLOWERS_FAILED,
        followers: []
      };
    }
    case FOLLOWINGS_REQUESTED: {
      return {
        ...state,
        status: FOLLOWINGS_REQUESTED
      };
    }
    case FOLLOWINGS_RETRIEVED: {
      return {
        ...state,
        status: FOLLOWINGS_RETRIEVED,
        followings: action.followings
      };
    }
    case FOLLOWINGS_FAILED: {
      return {
        ...state,
        status: FOLLOWINGS_FAILED,
        followings: []
      };
    }
    case SOCIAL_FEED_REQUESTED: {
      return {
        ...state,
        status: SOCIAL_FEED_REQUESTED
      };
    }
    case SOCIAL_FEED_RETRIEVED: {
      return {
        ...state,
        status: SOCIAL_FEED_RETRIEVED,
        feed: action.events.filter(n => n !== null)
      };
    }
    case SOCIAL_FEED_FAILED: {
      return {
        ...state,
        status: SOCIAL_FEED_FAILED,
        feed: []
      };
    }
    case USER_NOTIFICATIONS_FAILED: {
      return {
        ...state,
        status: USER_NOTIFICATIONS_FAILED,
        notificationType: action.notificationType
      };
    }
    case USER_NOTIFICATIONS_REQUESTED: {
      return {
        ...state,
        status: USER_NOTIFICATIONS_REQUESTED,
        notificationType: action.notificationType
      };
    }
    case USER_NOTIFICATIONS_RETRIEVED: {
      return {
        ...state,
        status: USER_NOTIFICATIONS_RETRIEVED,
        notifications: {
          ...state.notifications,
          [action.notificationType]: action.notifications.filter(
            n => n !== null
          )
        },
        hasMoreNotifications: action.notifications.length === 30,
        notificationType: action.notificationType
      };
    }
    case NOTIFICATION_READ_REQUESTED: {
      return {
        ...state,
        status: NOTIFICATION_READ_REQUESTED
      };
    }
    case NOTIFICATION_READ_SUCCESFULL: {
      return {
        ...state,
        status: NOTIFICATION_READ_SUCCESFULL
      };
    }
    case NOTIFICATION_READ_ERRORED: {
      return {
        ...state,
        status: NOTIFICATION_READ_ERRORED
      };
    }
    case ACTIVE_FOLLOWINGS_REQUESTED: {
      return {
        ...state,
        status: ACTIVE_FOLLOWINGS_REQUESTED
      };
    }
    case ACTIVE_FOLLOWINGS_SUCCESSFULL: {
      return {
        ...state,
        status: ACTIVE_FOLLOWINGS_SUCCESSFULL,
        activeFollowings: action.followings
      };
    }
    case ACTIVE_FOLLOWINGS_ERRORED: {
      return {
        ...state,
        status: ACTIVE_FOLLOWINGS_ERRORED,
        error: action.error
      };
    }
    default:
      return state;
  }
};
