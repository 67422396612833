import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Rating from '@material-ui/lab/Rating';
import { ReactComponent as StarIcon } from '../images/Icons/star.svg';

export default withStyles(() => ({
  iconFilled: {
    color: '#ffe400'
  },
  iconHover: {
    color: '#ffe400'
  },
  iconEmpty: {
    '& > svg': {
      '& > svg': {
        stroke: '#ffe400',
        fill: 'rgba(255, 255, 255, 0.1)'
      }
    }
  }
}))(props => (
  <Rating
    size="small"
    icon={
      <SvgIcon>
        <StarIcon />
      </SvgIcon>
    }
    {...props}
  />
));
