import TwoForOne from '../images/discounts/Promo_2x1_120x120.png';
import ThreeForTwo from '../images/discounts/Promo_3x2_120x120.png';
import TwentyOff from '../images/discounts/Promo_20off_120x120.png';
import ThirtyOff from '../images/discounts/Promo_30off_120x120.png';
import FortyOff from '../images/discounts/Promo_40off_120x120.png';
import Offer from '../images/discounts/Promo_Oferta_120x120.png';
import FreeShipping from '../images/discounts/envio_gratis_mediana.png';

import BannerTwoForOne from '../images/Promos/Promos_2x1.png';
import BannerThreeForTwo from '../images/Promos/Promos_3x2.png';
import BannerTwenty from '../images/Promos/Promos_20_.png';
import BannerThirty from '../images/Promos/Promos_30_.png';
import BannerForty from '../images/Promos/Promos_40_.png';
import BannerFreeShipping from '../images/Promos/Promos_EnviosGratis.png';

import Campaign from '../images/Promos/Promos_DctoCeleb.png';

const TWO_FOR_ONE = 'TWO_FOR_ONE';
const THREE_FOR_TWO = 'THREE_FOR_TWO';
const TWENTY_PERCENT = 'TWENTY_PERCENT';
const THIRTY_PERCENT = 'THIRTY_PERCENT';
const FORTY_PERCENT = 'FORTY_PERCENT';
const FREE_SHIPPING = 'FREE_SHIPPING';

export const getBannerDiscount = promotion => {
  if (promotion.entity === 'Campaign') {
    return Campaign;
  }
  if (promotion) {
    switch (promotion.type) {
      case TWO_FOR_ONE:
        return BannerTwoForOne;
      case THREE_FOR_TWO:
        return BannerThreeForTwo;
      case TWENTY_PERCENT:
        return BannerTwenty;
      case THIRTY_PERCENT:
        return BannerThirty;
      case FORTY_PERCENT:
        return BannerForty;
      case FREE_SHIPPING:
        return BannerFreeShipping;
      default:
        return null;
    }
  }

  return null;
};

export const getPromotionImage = promotion => {
  switch (promotion.type) {
    case TWO_FOR_ONE:
      return TwoForOne;
    case THREE_FOR_TWO:
      return ThreeForTwo;
    case TWENTY_PERCENT:
      return TwentyOff;
    case THIRTY_PERCENT:
      return ThirtyOff;
    case FORTY_PERCENT:
      return FortyOff;
    case FREE_SHIPPING:
      return FreeShipping;
    default:
      return null;
  }
};

export default product => {
  if (!product) {
    return null;
  }

  const { promotion } = product.user;

  if (promotion !== null) {
    return getPromotionImage(promotion);
  }

  // If the product's owner doesn't have a promotion active, and the selling_price
  // is different that the final_price, then it means we have an active offer on the product.
  if (product.selling_price !== product.final_price) {
    return Offer;
  }

  return null;
};
