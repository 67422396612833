import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase, Typography } from '@material-ui/core';

import WomenPicture from '../images/mujer-1.png';
import KidsPicture from '../images/kids.png';
import MenPicture from '../images/categoriaHombre.png';

// Subcategories
import RopaPicture from '../images/2020_Fotos_Menu/ropaaa.png';
import AccesoriesPicture from '../images/2020_Fotos_Menu/accs.png';
import CalzadoPicture from '../images/2020_Fotos_Menu/calzado1.png';
import BellezaPicture from '../images/2020_Fotos_Menu/bellza1.png';
import AltaCosturaPicture from '../images/2020_Fotos_Menu/acos.png';

import BebesPicture from '../images/2020_Fotos_Menu/BB.png';
import BoyPicture from '../images/2020_Fotos_Menu/boy.png';
import GirlPicture from '../images/2020_Fotos_Menu/ninia.png';

import menAccesories from '../images/2020_Fotos_Menu/accesoriosHombre.png';
import menShoes from '../images/2020_Fotos_Menu/calzadoHombre.png';
import menBeauty from '../images/2020_Fotos_Menu/bellezaHombre.png';
import menClothes from '../images/2020_Fotos_Menu/ropaHombre.png';

// Brands
import NikePicture from '../images/2020_Fotos_Menu/NIKE.png';
import HYMPicture from '../images/2020_Fotos_Menu/HYM.png';
import AdidasPicture from '../images/2020_Fotos_Menu/adidas.png';
import ZaraPicture from '../images/2020_Fotos_Menu/zara.png';
import PrunePicture from '../images/2020_Fotos_Menu/prune.png';

export const ButtonVariants = {
  mujer: WomenPicture,
  kids: KidsPicture,
  hombre: MenPicture,
  mujerBelleza: BellezaPicture,
  mujerAccesorios: AccesoriesPicture,
  'mujer Alta costura': AltaCosturaPicture,
  mujerRopa: RopaPicture,
  mujerCalzado: CalzadoPicture,
  kidsBebés: BebesPicture,
  kidsNiñas: GirlPicture,
  kidsNiños: BoyPicture,
  hombreBelleza: menBeauty,
  hombreAccesorios: menAccesories,
  hombreCalzado: menShoes,
  hombreRopa: menClothes,
  adidas: AdidasPicture,
  'h&m': HYMPicture,
  nike: NikePicture,
  zara: ZaraPicture,
  prüne: PrunePicture
};

const useStyles = makeStyles(theme => ({
  root: props => {
    let height = 'unset';
    let width = 'unset';
    let maxHeight = 'unset';
    let maxWidth = 'unset';

    if (props.variant !== 'custom') {
      if (props.variant === 'wide') {
        height = '75px';
        maxHeight = '75px';
        width = '250px';
        maxWidth = '250px';
      } else {
        height = '100px';
        maxHeight = '100px';
        width = '200px';
        maxWidth = '200px';
      }
    }

    return {
      height,
      width,
      maxHeight,
      maxWidth,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      backgroundColor: props.selected ? theme.palette.primary.light : '#efeded',
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(1),
      '&:hover': props.noHover
        ? null
        : {
            backgroundColor: theme.palette.primary.light
          },
      '@media (max-height: 677px)': {
        height: props.variant === 'wide' ? '50px' : height,
        alignItems: props.variant === 'wide' ? 'flex-start' : 'center'
      }
    };
  },
  buttonTitle: ({ fontWeight }) => ({
    margin: theme.spacing(0, 1, 0, 1),
    textTransform: 'uppercase',
    fontWeight: fontWeight || 500,
    flex: 1,
    textAlign: 'left'
  })
}));

const RTVButton = props => {
  const classes = useStyles(props);
  const { children, custom, variant, kind, src, ...other } = props;
  return (
    <ButtonBase {...other} className={classes.root}>
      {variant !== 'custom' ? (
        <Typography component="span" className={classes.buttonTitle}>
          {children}
        </Typography>
      ) : (
        custom
      )}
      {variant !== 'custom' && (
        <img
          style={{ height: '100px', width: '100px' }}
          alt={children}
          src={src || ButtonVariants[kind] || WomenPicture}
        />
      )}
    </ButtonBase>
  );
};

export default RTVButton;
