import { Button, Snackbar } from '@material-ui/core';
import { push } from 'connected-react-router';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../redux/actions/user';

const OnLive = () => {
  const [openSnack, setOpenSnack] = useState(false);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  // We send a custom event that the app's webview will be listening
  // to in order to act upon it. We use only one event with a different type.
  const dispatchEvent = (type, item) => {
    window.dispatchEvent(
      new CustomEvent('onlive-event', {
        detail: {
          type,
          item
        }
      })
    );
  };

  React.useEffect(() => {
    (function () {
      window.onliveWidgets = window.onliveWidgets || {};
      window['Onlive-Widget-tv'] = window.onliveWidgets['Onlive-Widget-tv'] =
        'onliveWtv';
      window['onliveWtv'] =
        window['onliveWtv'] ||
        function () {
          (window['onliveWtv'].q = window['onliveWtv'].q || []).push(arguments);
        };
      const js = document.createElement('script');
      const fjs = document.getElementsByTagName('script')[0];
      js.id = 'onliveWtv';
      js.src = 'https://cdn.onlive.site/widget/v2/onlive-widget.js';
      js.async = 1;
      fjs.parentNode.insertBefore(js, fjs);
    })();

    // Maybe we could wait after the past function runs before actually trying to use `onliveWtv`.
    window.onliveWtv('init', {
      landing: true,
      targetElementId: 'onlive-landing',
      organizationId: '5ab24f26-1e64-4427-82e8-9cd821b80bd3',
      customs: {
        addToCart: (_instance, cartItem, _discountCode, _user) => {
          // Trigger event for app webview in case seen from there. If not logged in in App, ignored
          dispatchEvent('cart-add', cartItem.id);
          // LoggedIn on web means onLive is probably originally watched from here
          if (isLoggedIn) {
            dispatch(addToCart(cartItem.id));
            setOpenSnack(true);
          }
        },
        checkout: (_instance, cartItem, _discountCode, _user) => {
          // Trigger event for app webview
          dispatchEvent('checkout', cartItem.id);
          if (isLoggedIn) {
            dispatch(addToCart(cartItem.id));
            dispatch(push('/checkout/carrito'));
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Snackbar
        open={openSnack}
        style={{ zIndex: 99999999 }}
        onClose={() => setOpenSnack(false)}
        autoHideDuration={6000}
        message="Agregamos el producto a tu carrito"
        action={
          <Button
            size="small"
            onClick={() => window.open('/checkout/carrito', '_blank')}
            color="primary"
          >
            Ir al checkout
          </Button>
        }
      />
      <div id="onlive-landing" />
    </>
  );
};

export default OnLive;
