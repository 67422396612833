import {
  FOLLOW_REQUESTED,
  FOLLOW_SUCCEED,
  FOLLOW_FAILED,
  UNFOLLOW_REQUESTED,
  UNFOLLOW_SUCCEED,
  UNFOLLOW_FAILED,
  FOLLOWERS_REQUESTED,
  FOLLOWERS_RETRIEVED,
  FOLLOWERS_FAILED,
  FOLLOWINGS_REQUESTED,
  FOLLOWINGS_RETRIEVED,
  FOLLOWINGS_FAILED,
  SOCIAL_FEED_REQUESTED,
  SOCIAL_FEED_RETRIEVED,
  SOCIAL_FEED_FAILED,
  FOLLOW_LIST_REQUESTED,
  FOLLOW_LIST_RETRIEVED,
  FOLLOW_LIST_FAILED,
  USER_NOTIFICATIONS_FAILED,
  USER_NOTIFICATIONS_REQUESTED,
  USER_NOTIFICATIONS_RETRIEVED,
  NOTIFICATION_READ_REQUESTED,
  NOTIFICATION_READ_SUCCESFULL,
  NOTIFICATION_READ_ERRORED,
  ACTIVE_FOLLOWINGS_REQUESTED,
  ACTIVE_FOLLOWINGS_SUCCESSFULL,
  ACTIVE_FOLLOWINGS_ERRORED
} from './types';
import SocialRestClient from '../../http/client/Social';

export const getFollowList = user => {
  const requested = () => ({ type: FOLLOW_LIST_REQUESTED });
  const successfull = response => ({
    type: FOLLOW_LIST_RETRIEVED,
    followings: response.data
  });
  const errored = error => ({ type: FOLLOW_LIST_FAILED, error });
  return dispatch => {
    dispatch(requested());

    return SocialRestClient.getFollowList(user)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const follow = user => {
  const requested = () => ({ type: FOLLOW_REQUESTED, user });
  const successfull = () => ({ type: FOLLOW_SUCCEED, user });
  const errored = error => ({ type: FOLLOW_FAILED, user, error });

  return dispatch => {
    dispatch(requested());

    return SocialRestClient.follow(user)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const unfollow = user => {
  const requested = () => ({ type: UNFOLLOW_REQUESTED, user });
  const successfull = () => ({ type: UNFOLLOW_SUCCEED, user });
  const errored = error => ({ type: UNFOLLOW_FAILED, error, user });

  return dispatch => {
    dispatch(requested());

    return SocialRestClient.unfollow(user)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getFollowings = (
  user,
  promoFilter = false,
  page = 1,
  pageSize = 10
) => {
  const requested = () => ({ type: FOLLOWINGS_REQUESTED });
  const successfull = response => ({
    type: FOLLOWINGS_RETRIEVED,
    followings: response.data.items || response.data,
    totals: response.data.totals
  });
  const errored = error => ({ type: FOLLOWINGS_FAILED, error, user });

  return dispatch => {
    dispatch(requested());

    return SocialRestClient.followings(user, promoFilter, page, pageSize)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getFollowers = (user, page = 1, pageSize = 10) => {
  const requested = () => ({ type: FOLLOWERS_REQUESTED, user });
  const successfull = response => ({
    type: FOLLOWERS_RETRIEVED,
    followers: response.data.items || response.data,
    totals: response.data.totals
  });
  const errored = error => ({ type: FOLLOWERS_FAILED, error, user });

  return dispatch => {
    dispatch(requested());

    return SocialRestClient.followers(user, page, pageSize)
      .then(response => {
        if (response.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const fetchFeed = offset => {
  const requested = () => ({ type: SOCIAL_FEED_REQUESTED });
  const successfull = response => ({
    type: SOCIAL_FEED_RETRIEVED,
    events: response.data.items || response.data
  });
  const errored = error => ({ type: SOCIAL_FEED_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return SocialRestClient.feed(offset)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getUserNotifications = (
  notificationType = 'all',
  offset = 0,
  pageSize
) => {
  const requested = () => ({
    type: USER_NOTIFICATIONS_REQUESTED,
    notificationType
  });
  const successfull = response => ({
    type: USER_NOTIFICATIONS_RETRIEVED,
    notifications: response.data.items || response.data,
    notificationType
  });
  const errored = error => ({
    type: USER_NOTIFICATIONS_FAILED,
    error,
    notificationType
  });

  return dispatch => {
    dispatch(requested());

    return SocialRestClient.getUserNotifications(
      notificationType,
      offset,
      pageSize
    )
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const readNotification = (notification, clear = true) => {
  const requested = () => ({ type: NOTIFICATION_READ_REQUESTED });
  const successfull = () => ({ type: NOTIFICATION_READ_SUCCESFULL, clear });
  const errored = error => ({ type: NOTIFICATION_READ_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return SocialRestClient.readNotification(notification)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getActiveFollowings = (page, pageSize) => {
  const requested = () => ({ type: ACTIVE_FOLLOWINGS_REQUESTED });
  const successfull = response => ({
    type: ACTIVE_FOLLOWINGS_SUCCESSFULL,
    followings: response.data
  });
  const errored = error => ({ type: ACTIVE_FOLLOWINGS_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return SocialRestClient.activeFollowings(page, pageSize)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};
