import {
  BANNERS_REQUESTED,
  BANNERS_RETRIEVED,
  BANNERS_FAILED,
  TEXT_BANNERS_REQUESTED,
  TEXT_BANNERS_RETRIEVED,
  TEXT_BANNERS_FAILED
} from '../actions/types';

const defaultState = {
  banners: [],
  textBanners: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case BANNERS_REQUESTED: {
      return {
        ...state,
        status: BANNERS_REQUESTED,
        error: null,
        banners: []
      };
    }
    case BANNERS_RETRIEVED: {
      return {
        ...state,
        status: BANNERS_RETRIEVED,
        // Filter only the ones which have the appropiate type
        // since we will be using only these for the time being
        // ref: https://github.com/RenuevaTuCloset/web/issues/214
        banners: action.banners
          .filter(b => b.type === 'ImageBanner')
          .sort((a, b) => a.priority - b.priority)
      };
    }
    case BANNERS_FAILED: {
      return {
        ...state,
        status: BANNERS_FAILED,
        banners: [],
        error: action.error
      };
    }
    case TEXT_BANNERS_REQUESTED: {
      return {
        ...state,
        status: TEXT_BANNERS_REQUESTED,
        textBanners: []
      };
    }
    case TEXT_BANNERS_RETRIEVED: {
      return {
        ...state,
        status: TEXT_BANNERS_RETRIEVED,
        textBanners: action.banners
      };
    }
    case TEXT_BANNERS_FAILED: {
      return {
        ...state,
        status: TEXT_BANNERS_FAILED,
        textBanners: [],
        error: action.error
      };
    }
    default:
      return state;
  }
};
