/**
 * TODO(Toti): This function may return a string or a number if
 * the abbreviation is not required, we might need to make it consistent
 * and always return a string so we know it's only for displaying information
 * and not to do any type of calculation on it.
 */
/* eslint-disable */
export default (number, decimalPlaces = 1) => {
  // Negative number handling
  let negative = number < 0;

  if (negative) {
    number = number * -1;
  }

  // 2 decimal places => 100, 3 => 1000, etc
  // eslint-disable-next-line
  decimalPlaces = Math.pow(10, decimalPlaces);

  // Enumerate number abbreviations
  const abbrev = [' K', ' M', ' B', ' T'];

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to '1000', '1000000', etc
    // eslint-disable-next-line
    const size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decimalPlaces, round, and then divide by decimalPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decimalPlaces) / size) / decimalPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number += abbrev[i];

      // We are done... stop
      break;
    }
  }

  if (typeof number === 'string' && negative) {
    number = '-' + number;
  } else if (negative) {
    number = number * -1;
  }

  return number;
};
