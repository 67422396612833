import {
  REGISTER_PUSH_TOKEN_REQUESTED,
  REGISTER_PUSH_TOKEN_SUCCESSFULL,
  REGISTER_PUSH_TOKEN_ERRORED,
  UNREGISTER_PUSH_TOKEN_REQUESTED,
  UNREGISTER_PUSH_TOKEN_SUCCESSFULL,
  UNREGISTER_PUSH_TOKEN_ERRORED
} from './types';
import CommunicationRestClient from '../../http/client/Communication';
import store from '../store';

export const registerToken = token => {
  const requested = () => ({ type: REGISTER_PUSH_TOKEN_REQUESTED });
  const successfull = () => ({ type: REGISTER_PUSH_TOKEN_SUCCESSFULL, token });
  const errored = () => ({ type: REGISTER_PUSH_TOKEN_ERRORED });

  return dispatch => {
    dispatch(requested());

    return CommunicationRestClient.registerToken(token)
      .then(() => dispatch(successfull()))
      .catch(() => dispatch(errored()));
  };
};

export const unregisterToken = () => {
  const requested = () => ({ type: UNREGISTER_PUSH_TOKEN_REQUESTED });
  const successfull = () => ({ type: UNREGISTER_PUSH_TOKEN_SUCCESSFULL });
  const errored = () => ({ type: UNREGISTER_PUSH_TOKEN_ERRORED });

  return dispatch => {
    if (!store.getState().communication.token) {
      return Promise.resolve(dispatch(errored()));
    }
    dispatch(requested());

    return CommunicationRestClient.unregisterToken(
      store.getState().communication.token
    )
      .then(() => dispatch(successfull()))
      .catch(() => dispatch(errored()));
  };
};
