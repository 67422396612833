/* eslint-disable no-param-reassign */
export default tree =>
  tree.reduce((hash, category) => {
    const hashedCategory = {
      ...category,
      sub_categories: category.sub_categories.reduce((subHash, subCategory) => {
        const hashedSubCategories = {
          ...subCategory,
          sub_sub_categories: subCategory.sub_sub_categories.reduce(
            (subSubHash, subSubCategory) => {
              subSubHash[
                encodeURI(
                  subSubCategory.name.toLowerCase().split(' ').join('-')
                )
              ] = subSubCategory;
              return subSubHash;
            },
            {}
          )
        };
        subHash[
          encodeURI(subCategory.name.toLowerCase().split(' ').join('-'))
        ] = hashedSubCategories;
        return subHash;
      }, {})
    };

    hash[
      encodeURI(category.name.toLowerCase().split(' ').join('-'))
    ] = hashedCategory;

    return hash;
  }, {});
