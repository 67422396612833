import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';
import RTVAvatar from '../RTVAvatar';

const styles = theme => ({
  divider: {
    borderBottomColor: fade(theme.palette.primary.main, 0.2)
  },
  primaryText: {
    fontSize: '1.2rem',
    divider: {
      borderBottomColor: fade(theme.palette.primary.main, 0.2)
    },
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      fontSize: '1.2rem'
    },
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column',
      alignItems: 'flex-start',
      '& > p': {
        marginLeft: '0 !important'
      }
    }
  },
  textLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  avatar: {
    width: '60px',
    height: '60px',
    boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.06)',
    [theme.breakpoints.down('xs')]: {
      width: '56px',
      height: '56px'
    }
  },
  listAvatar: {
    marginRight: theme.spacing(1)
  }
});

const ProductLike = ({ notification, key, classes, type, noBorder }) => (
  <ListItem
    key={key}
    alignItems="flex-start"
    divider={!noBorder}
    classes={{
      divider: classes.divider
    }}
  >
    {type === 'feed' && (
      <>
        <ListItemAvatar>
          <RTVAvatar
            src={
              notification.other_user.media
                ? notification.other_user.media.default_small
                : null
            }
            alt="avatar"
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.primaryText}>
              <Link
                to={`/vestidor/${notification.other_user.username}`}
                className={classes.textLink}
              >
                {notification.other_user.username}
              </Link>
            </Typography>
          }
          secondary={
            <>
              <Typography color="textPrimary">
                le gustó{' '}
                <Link
                  className={classes.textLink}
                  to={`/producto/id/${notification.product.id}`}
                >
                  {notification.product.title}
                </Link>
              </Typography>
              {notification.timePassedString}
            </>
          }
        />
      </>
    )}
    {type !== 'feed' && (
      <>
        <ListItemAvatar className={classes.listAvatar}>
          <RTVAvatar
            className={classes.avatar}
            src={
              notification.other_user.media
                ? notification.other_user.media.default_small
                : null
            }
            alt="avatar"
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.primaryText}>
              <Link
                to={`/vestidor/${notification.other_user.username}`}
                className={classes.textLink}
              >
                {notification.other_user.username}
              </Link>
              <Typography style={{ fontWeight: 400, marginLeft: '4px' }}>
                le gustó{' '}
                <Link
                  className={classes.textLink}
                  to={`/producto/id/${notification.product.id}`}
                >
                  {notification.product.title}
                </Link>
              </Typography>
            </Typography>
          }
          secondary={notification.timePassedString}
        />
      </>
    )}
  </ListItem>
);

export default withStyles(styles)(ProductLike);
