import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Snackbar } from '@material-ui/core';
import { SW_UPDATE_FINISHED } from '../redux/actions/types';

const RTVSwUpdate = () => {
  const swUpdate = useSelector(state => state.app.swUpdate);
  const swReg = useSelector(state => state.app.swReg);
  const dispatch = useDispatch();

  const updateSw = () => {
    const swWaiting = swReg && swReg.waiting;
    if (swWaiting) {
      dispatch({ type: SW_UPDATE_FINISHED });
      swWaiting.postMessage({ type: 'SKIP_WAITING' });
      swWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          dispatch({ type: SW_UPDATE_FINISHED });
          window.location.reload();
        }
      });
    } else {
      dispatch({ type: SW_UPDATE_FINISHED });
    }
  };

  return (
    <Snackbar
      open={swUpdate}
      color="primary"
      message="Nueva version de RTV Disponible! 🎊"
      action={
        <Button color="primary" size="small" onClick={updateSw}>
          ACTUALIZAR
        </Button>
      }
    />
  );
};

export default RTVSwUpdate;
